import React from 'react'
import style from './index.module.less'
import FooterList from './FooterList'
import useNavigateLink from "@/hooks/useNavigateLink";
import { Link } from '@reach/router';
import EmailForm from './EmailForm'
import getCountry from '@/utils/getCountry.js'
import LangList from './NewLangList'
import { useIntl } from 'react-intl';

const Footer = () =>{
    const country = getCountry()
    const intl = useIntl()
    const getLinkUrl = (link) => {
        const url = useNavigateLink(link)
        return url
    }
    const link_arr=[
        {
            name:intl.formatMessage({ id: "terms_of_service" }),
            link:"/terms-of-service"
        },{
            name:intl.formatMessage({ id: "privacy_policy" }),
            link:"/privacy-policy"
        },{
            name:intl.formatMessage({ id: "return_and_refund_policy" }),
            link:"/return-refund-policy"
        }
    ]
    return(
        <div className={style.footer}>
            <div className={style.footerContent}>
                <FooterList />
                <div className={style.footerContentRight}>
                    <EmailForm />
                    <LangList country={country} />
                </div>
            </div>
            <div className={style.footerMessage}>
                <div className={style.footerMessageLeft}>
                © XREAL, Inc. All Rights Reserved.
                </div>
                <div className={style.footerMessageRight}>
                    {link_arr.map(item=>(
                        <Link to={getLinkUrl(item.link)} key={`footerMessageLink-${item.name}`}
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Footer