import React, { useContext } from "react";
import style from './index.module.less'
import { LangStateContext } from "@/context/langContext"
import { useIntl } from "react-intl"
import classNames from "classnames";
import closeSVG from "@/images/icons/cookieClose.svg"

const CookieBanner = () => {
  const intl = useIntl()

  const lang = useContext(LangStateContext)
  const [shouldSeeCookieBanner, toggleBanner] = React.useState(false)
 
  React.useEffect(() => {
    toggleBanner(localStorage.getItem("cookie_banner") !== "0")
  }, [toggleBanner])

  const onCloseClicked = React.useCallback(() => {
    toggleBanner(false)
    localStorage.setItem("cookie_banner", "0")
  }, [toggleBanner])

  return (
    shouldSeeCookieBanner && (
      <div className={classNames(style.wrapper, 'cookieBox')}>
        <div className={style.textWrapper} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cookie_text" }) }} >

        </div>
        <img className={style.close} src={closeSVG} onClick={onCloseClicked} />

      </div>
    )
  )
}

export default CookieBanner