import React, { useRef, useEffect, useState } from "react";
import style from "./index.module.less"
import { Link } from "gatsby"
import {
    XrealLogo
} from "@/images/menu"
import { useIntl } from 'react-intl';
import useGa from '@/hooks/useGa'
import getCountry from '@/utils/getCountry.js'
import CheckIpBox from '@/new_components/header/checkip.js'
import home_buyBox from '@/images/home/home_buyBox.svg'

const NormalMenu = ({ showsubmenu, getLinkUrl, shopUrl, menu, submenu }) => {
    const intl = useIntl()
    const region = getCountry()
    const menuRef = useRef(null)
    const headerRef = useRef(null)
    const [subMenuShow, setSubMenuShow] = useState(showsubmenu)
    useEffect(() => {
        document.body.style.overflow = 'auto'
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    let lastScrollTop = 0;
    const handleScroll = () => {
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;
        const whentohide = 200
        if (scrollDistance > 0 && currentScrollTop >= whentohide) {
            menuRef.current.style.opacity = '0'
            headerRef.current.style.transform = `translateY(-100%)`
        } else {
            menuRef.current.style.opacity = '1'
            headerRef.current.style.transform = `translateY(0)`
        }
    }


    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id, product_name) => {
        if (!!product_name) {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id,
                    products_name: product_name
                }
            });
        } else {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id
                }
            });
        }

    }

    return (
        <>
            <div className={style.header} ref={headerRef}>
                {/* ip判断提示 */}
                <CheckIpBox ifPc={true} />
                <div className={style.menu} ref={menuRef}>
                    <div className={style.outer}>
                        <Link to={getLinkUrl("/")} className={style.left} onClick={() => { addGa('menu_home'), addSensor(10000) }}>
                            <XrealLogo />
                        </Link>

                        <div className={style.right} onClick={() => addGa('menu_shop', addSensor(10004))}>
                            <div className={style.reddiv}></div>
                            <div className={style.text}>{intl.formatMessage({ id: "shop_text", defaultMessage: "Shop" })}</div>
                            <div className={style.buyBox}>
                                <img src={home_buyBox} className={style.buyBoxBg}/>
                                <div className={style.buyBoxBtn} onClick={() => window.open('https://shopee.co.th/xrealofficialstore')}>
                                    <span>SHOPEE</span>
                                </div>
                                <div className={style.buyBoxBtn} onClick={() => window.open('https://www.lazada.co.th/shop/xrealofficialstore')}>
                                    <span>LAZADA</span>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className={style.inner}>
                        {menu.map((item, idx) => {
                            return <Link key={idx} to={getLinkUrl(item.link)} className={style.innerItem} onClick={() => { addGa(item.id), addSensor(item.module_id) }}
                                onMouseEnter={() => item.id == 'menu.products' && setSubMenuShow(true)}
                                onMouseLeave={() => item.id == 'menu.products' && setSubMenuShow(false)}
                            >
                                {intl.formatMessage({ id: item.id, defaultMessage: item.title })}
                            </Link>
                        })}
                    </div>
                </div>

                <div className={style.submenuBox}
                    style={{ opacity: (showsubmenu && submenu.length > 0) || (!!subMenuShow) ? 1 : 0, pointerEvents: (showsubmenu && submenu.length > 0) || (!!subMenuShow) ? 'auto' : 'none' }}
                    onMouseEnter={() => setSubMenuShow(true)}
                    onMouseLeave={() => setSubMenuShow(false)}>
                    <div className={style.submenu} >
                        {submenu.map((item, idx) => {
                            return <Link key={idx} to={getLinkUrl(item.link)} className={style.submenuItem} activeClassName={style.submenuAct} onClick={() => { addGa(item.id), addSensor(item.module_id, item.name) }}>{item.icon}</Link>
                        })}
                    </div>
                </div>

            </div>
        </>
    )
}

export default NormalMenu