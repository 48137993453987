import React, { useEffect } from "react";
import style from './index.module.less'
import EmailForm from "./EmailForm";
import FooterList from './FooterList'
import LangList from './NewLangList'
import getCountry from '@/utils/getCountry.js'
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl";
import useGa from '@/hooks/useGa'
import FooterHomeLogo from '@/images/SVG/footer-home-logo.svg'
const OldFooter = ({ footerBackground, footerTextWhite }) => {
    const country = getCountry()
    const intl = useIntl()

    document.documentElement.style.setProperty("--footer-opacity1", footerTextWhite ? 'rgba(255,255,255,1)' : "rgba(0,0,0,1)");
    document.documentElement.style.setProperty("--footer-opacity2", footerTextWhite ? 'rgba(255,255,255,0.2)' : "rgba(0,0,0,0.2)");
    document.documentElement.style.setProperty("--footer-opacity4", footerTextWhite ? 'rgba(255,255,255,0.4)' : "rgba(0,0,0,0.4)");
    document.documentElement.style.setProperty("--footer-opacity6", footerTextWhite ? 'rgba(255,255,255,0.6)' : "rgba(0,0,0,0.6)");
    document.documentElement.style.setProperty("--footer-opacity8", footerTextWhite ? 'rgba(255,255,255,0.8)' : "rgba(0,0,0,0.8)");

    return (
        <>
            {/* <CookieBanner /> */}
            <div className={style.footer} style={{ background: footerBackground }}>
                <div className={style.footerContent}>
                    <EmailForm footerTextWhite={footerTextWhite} />
                    <FooterList />
                </div>
                <div className={style.langIndex}>
                    <div className={style.langIndexContent}>
                        <div className={style.langIndexGo} onClick={()=>useNavigate('/')}>
                          <FooterHomeLogo/>   xreal.com
                        </div>
                        <LangList country={country} />
                    </div>

                </div>

                <div className={style.footerElse}>
                    <div className={style.footerElseICP}>
                        © XREAL, Inc. All Rights Reserved.
                    </div>
                    <div className={style.footerElsePT}>
                        <span onClick={() => { useGa("Footer", "footer_pp", "Click"), useNavigate('/privacy-policy') }}>{intl.formatMessage({ id: "privacy_policy" })}</span>
                        <span onClick={() => { useGa("Footer", "footer_tos", "Click"), useNavigate('/terms-of-service') }}>{intl.formatMessage({ id: "terms_of_service" })}</span>
                        <span onClick={() => { useGa("Footer", "footer_refundPolicy", "Click"), useNavigate('/return-refund-policy') }}>{intl.formatMessage({ id: "return_and_refund_policy" })}</span>
                    </div>
                </div>
            </div>
        </>

    )
}
export default OldFooter