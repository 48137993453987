// 是否题
import React, { useState } from "react"
import { SvgIcon } from '@/new_components/index';
import style from "./style.module.less"
import useGa from '@/hooks/useGa'
import YesIcon from '@/images/SVG/yes.svg'
import YesActiveIcon from '@/images/SVG/yes_active.svg'
import NoIcon from '@/images/SVG/no.svg'
import NoActiveIcon from '@/images/SVG/no_active.svg'

function SwitchQuestion(props) {
  const { qsItem, isMini = false, onChoose } = props;
  const initVal = qsItem?.value?.length > 0 ? qsItem?.value[0] : null;
  const [qsValue, setQsValue] = useState(initVal);

  const SwitchIcon = (props) => {
    const { iconItem, value, iconSize, onSelect } = props;
    const initValue = value?.label;
    const defaultIcon = iconItem.label === 'yes' ? 'yes' : 'no';
    const icon = !initValue ? defaultIcon : `${initValue}_active`;
    const [iconName, setIconName] = useState(icon);
    const size = !iconSize ? 36 : iconSize;

    const onHoverBtn = () => {
      if (!!initValue) return;
      if (iconName.includes('_active')) {
        setIconName(defaultIcon);
      } else {
        setIconName(`${defaultIcon}_active`);
      }
    }

    const onClick = (optionItem) => {
      if (!!value && optionItem.id == value.id) {
        console.log('已选，不可取消，只可切换')
      } else {
        onSelect(optionItem)
      }
    }

    const iconStyle = { width: size, height: size };
    let iconComponent = <YesIcon style={iconStyle} />;
    switch (iconName) {
      case "yes":
        iconComponent = <YesIcon style={iconStyle} />;
        break;
      case "no":
        iconComponent = <NoIcon style={iconStyle} />;
        break;
      case "no_active":
        iconComponent = <NoActiveIcon style={iconStyle} />;
        break;
      case "yes_active":
        iconComponent = <YesActiveIcon style={iconStyle} />;
        break;
      default:
        iconComponent = <YesIcon style={iconStyle} />;
        break;
    }

    return <div className={style.switchBtn} onClick={() => onClick(iconItem)} onMouseEnter={onHoverBtn} onMouseLeave={onHoverBtn}>
      {/* <SvgIcon iconName={iconName} width={size} height={size} /> */}
      <div style={iconStyle}>{iconComponent}</div>
    </div>
  }

  const onSelect = (optionItem) => {
    useGa("survey", `survey_${optionItem?.label}_${qsItem.name}`, "Click");
    setQsValue(optionItem);
    let showOrder = [];
    let hideOrder = [];
    qsItem.skip.map(item => {
      if (item.select.includes(optionItem.id)) {
        showOrder = item.showOrder
      } else {
        hideOrder = [...hideOrder, ...item.showOrder]
      }
    })
    // console.log('显示，隐藏', showOrder, hideOrder)
    if (!!onChoose) onChoose({ order: qsItem.order, name: qsItem.name, value: [optionItem], showOrder: showOrder, hideOrder: hideOrder });
  }

  // [mini]是否题
  const MiniSwitch = () => {
    return <div className={style.switchMini}>
      {
        qsItem?.options.map((item, _) => {
          const currentVal = qsValue?.id == item?.id ? qsValue : null;
          // console.log('[mini]是否题', currentVal)
          return <SwitchIcon key={item.id} iconItem={item} value={currentVal} iconSize={26} onSelect={onSelect} />
        })
      }
    </div>
  }

  // [普通]是否题
  const NormalSwitch = () => {
    return <div className={style.switch}>
      <div className={style.qsLabel}>{qsItem?.label}</div>
      <div className={style.switchOptions}>
        {
          qsItem?.options.map((item, _) => {
            const currentVal = qsValue?.id == item?.id ? qsValue : null;
            // console.log('[普通]是否题', currentVal)
            return <SwitchIcon key={item.id} iconItem={item} value={currentVal} onSelect={onSelect} />
          })
        }
      </div>
    </div>
  }

  return (
    <>
      {isMini ? <MiniSwitch /> : <NormalSwitch />}
    </>
  );
}

export default SwitchQuestion