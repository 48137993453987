//footer
const reddit = `${process.env.resourceUrl}images/logo/footer-reddit.png`
const ins = `${process.env.resourceUrl}images/logo/footer-ins.png`
const fb = `${process.env.resourceUrl}images/logo/footer-fcbook.png`
const tw = `${process.env.resourceUrl}images/logo/footer-tw.png`
const insB = `${process.env.resourceUrl}images/logo/insB.png`
const fbB = `${process.env.resourceUrl}images/logo/fbB.png`
const twB = `${process.env.resourceUrl}images/logo/twB.png`
const redditB = `${process.env.resourceUrl}images/logo/redditB.png`
import youtube from  './icons/youtube.svg'
import youtubeB from  './icons/youtubeB.svg'

//arlab
const cinemaAppPoster=`${process.env.resourceUrl}images/arlab/cinema-formac.jpg`
const cinemaAppHeader=`${process.env.resourceUrl}images/arlab/cinema-formac-header.jpg`
const cinemaDetail1=`${process.env.resourceUrl}images/arlab/cinemaDetail1.jpg`
const cinemaDetail2=`${process.env.resourceUrl}images/arlab/cinemaDetail2.jpg`
const cinemaDetail3=`${process.env.resourceUrl}images/arlab/cinemaDetail3.jpg`
const cinemaDetail4=`${process.env.resourceUrl}images/arlab/cinemaDetail4.jpg`
const cinemaDetail5=`${process.env.resourceUrl}images/arlab/cinemaDetail5.jpg`
const cinemaDetail6=`${process.env.resourceUrl}images/arlab/cinemaDetail6.jpg`
const cinemaDetail7=`${process.env.resourceUrl}images/arlab/cinemaDetail7.jpg`
//career
const career_hreo = `${process.env.resourceUrl}images/career/career_hero.webp`;
const cinemaMacDetail1=`${process.env.resourceUrl}images/arlab/cinemaDetail-1.jpg`
const cinemaMacDetail2=`${process.env.resourceUrl}images/arlab/cinemaDetail-2.jpg`
const cinemaMacDetail3=`${process.env.resourceUrl}images/arlab/cinemaDetail-3.jpg`
const cinemaMacDetail4=`${process.env.resourceUrl}images/arlab/cinemaDetail-4.jpg`
const cinemaMacDetail5=`${process.env.resourceUrl}images/arlab/cinemaDetail-5.jpg`
const cinemaMacDetail6=`${process.env.resourceUrl}images/arlab/cinemaDetail-6.jpg`
const cinemaMacDetail7=`${process.env.resourceUrl}images/arlab/cinemaDetail-7.jpg`
const cinemaMacDetail8=`${process.env.resourceUrl}images/arlab/cinemaDetail-8.jpg`
const cinemaMacDetail9=`${process.env.resourceUrl}images/arlab/cinemaDetail-9.jpg`
const cinemaMacDetail10=`${process.env.resourceUrl}images/arlab/cinemaDetail-10.jpg`
const cinemaMacDetail11=`${process.env.resourceUrl}images/arlab/cinemaDetail-11.jpg`

import email_icon from "./icons/email.svg"


//compatibility
import windowIcon from './icons/window.svg'
import macIcon from './icons/Mac.svg'
import appleIcon from './icons/apple.svg'
import androidPhoneIcon from './icons/Androidphone.svg'
import steamIcon from './icons/steam.svg'
import switchIcon from './icons/switch.svg'
import ps5Icon from './icons/ps5.svg'
import xboxIcon from './icons/xbox.svg'
import ipadIcon from './icons/ipad.svg'


import arrow_right from './icons/arrow_right.svg'


//nebula
let icon_get_it_on_google_2 = `${process.env.resourceUrl}images/logo/icon_get_it_on_google_2.jpg`
let screenmodel = `${process.env.resourceUrl}images/3D/nebula_interface.gltf`



//specs
const specs_light_tinified = `${process.env.resourceUrl}images/specs/specs_light_tinified.jpg`
const specs_air_tinified = `${process.env.resourceUrl}images/specs/specs_air_tinified.jpg`


const contactBg = `${process.env.resourceUrl}images/contentUs/contactBg.webp`


//svg
import points from './SVG/points.svg'
import BlackArrow from './SVG/blackArrow.svg'
import GoLink from './SVG/GoLink.svg'
import CloseIcon from './SVG/close.svg'
import CheckSmall from './SVG/checkSmall.svg'
import FooterEarth from './SVG/footer-earth.svg'


export {
	cinemaMacDetail1,
	cinemaMacDetail2,
	cinemaMacDetail3,
	cinemaMacDetail4,
	cinemaMacDetail5,
	cinemaMacDetail6,
	cinemaMacDetail7,
	cinemaMacDetail8,
	cinemaMacDetail9,
	cinemaMacDetail10,
	cinemaMacDetail11,
	cinemaDetail1,
	cinemaDetail2,
	cinemaDetail3,
	cinemaDetail4,
	cinemaDetail5,
	cinemaDetail6,
	cinemaDetail7,
	cinemaAppHeader,
	cinemaAppPoster,
	youtube,
	youtubeB,
	contactBg,
	CheckSmall,
	CloseIcon,
	GoLink,
	BlackArrow,
	screenmodel,
	specs_light_tinified,
	specs_air_tinified,
	icon_get_it_on_google_2,
	reddit,
	ins,
	fb,
	tw,
	career_hreo,
	points,
	email_icon,
	arrow_right,
	insB,
	fbB,
	twB,
	redditB,
	windowIcon,
	macIcon,
	appleIcon,
	androidPhoneIcon,
	steamIcon,
	switchIcon,
	ps5Icon,
	xboxIcon,
	ipadIcon,
	FooterEarth
}

