import React from "react";
import { navigate } from "gatsby";
import getCountry from '@/utils/getCountry.js'

const useNavigate =async (link,out)=>{
    let region =await getCountry()
   
    if(region=='base'){
        !!out?window.open(link):navigate(link)
    }else if(!!region){
        !!out?window.open(`/${region}${link}`): navigate(`/${region}${link}`)
    }
        
    
   
}

export default useNavigate