import React, { useEffect } from "react"
import useBigScreen from "@/hooks/bigScreen"
import useNavigateLink from "@/hooks/useNavigateLink";
import getCountry from '@/utils/getCountry.js'
import { menu, submenu } from "./const_menulist"
import NormalMenu from "./NormalMenu"
import MobileMenu from "./MobileMenu"
import { buy_link } from '@/scripts/constant.js'

const OldHeader = ({ menuBlack, showsubmenu }) => {
  useEffect(() => {
    const cssVariables = {
      "--menu-textColor": menuBlack ? "#111" : '#fff',
      "--menu-borderColor": menuBlack ? "rgba(0, 0, 0, 0.05)" : "rgba(255, 255, 255, 0.1)",
      "--menu-svgColor": menuBlack ? "#000" : '#fff',
      "--menu-subbgColor": menuBlack ? "rgba(233, 233, 233, 0.5)" : "rgba(255, 255, 255, 0.2)",
      "--menu-checkip-textColor": menuBlack ? "#333" : "#fff",
      "--menu-checkip-btnColor": menuBlack ? "rgba(0,0,0,0.8)" : "rgba(255, 255, 255, 0.80)",
      "--menu-checkip-btnHoverColor": menuBlack ? "rgba(255, 255, 255, 0.80)" : "rgba(0,0,0,0.8)",
      "--menu-checkip-background": menuBlack ? "rgba(233, 233, 233, 0.50)" : "rgba(102, 102, 102, 0.50)",
      "--menu-checkip-boderColor": menuBlack ? "rgba(0, 0, 0, 0.10)" : "rgba(255, 255, 255, 0.20)"
    };

    const root = document.documentElement;

    for (const [variable, value] of Object.entries(cssVariables)) {
      root.style.setProperty(variable, value);
    }
  }, [menuBlack]);

  const bigScreen = useBigScreen(768)
  const region = getCountry()   //['cn', 'jp', 'kr','th']
  const shopUrl = buy_link[region]['shop']
  const getLinkUrl = (link) => {
    const url = useNavigateLink(link)
    return url
  }
  return <>
    {bigScreen && <NormalMenu menu={menu} submenu={submenu} showsubmenu={showsubmenu} getLinkUrl={getLinkUrl} shopUrl={shopUrl} />}
    {!bigScreen && <MobileMenu getLinkUrl={getLinkUrl} shopUrl={shopUrl} menu={menu} submenu={submenu} />}
  </>
}

export default OldHeader
