import React from "react"
import {
    MenuMBAir2,
    MenuMBAdapter,
    MenuMBAir,
    MenuMBBeam,
} from "@/images/menu"
// id for intl   {"menu_products","menu_ar","menu_support"}
const menu = [
    {
        id: "menu.products",
        link: "/air2",
        type: "text",
        title: "Products",
        module_id:10001
    }, {
        id: "menu.ar",
        link: "/ar",
        type: "text",
        title: "AR",
        module_id:10002
    }, {
        id: "menu.support",
        link: "/support",
        type: "text",
        title: "Support",
        module_id:10003
    }
]

const submenu = [
    {
        id: "menu.product.air2",
        link: "/air2",
        title: "Air2",
        type: "icon",
        icon: <MenuMBAir2 />,
        module_id:10009,
        name:'air2',

    }, {
        id: "menu.product.air",
        link: "/air",
        title: "Air",
        type: "icon",
        icon: <MenuMBAir />,
        module_id:10009,
        name:'air'

    }, {
        id: "menu.product.beam",
        link: "/beam",
        title: "Beam",
        type: "icon",
        icon: <MenuMBBeam />,
        module_id:10009,
        name:'beam'

    }, {
        id: "menu.product.adapter",
        link: "/adapter",
        title: "Adapter",
        type: "icon",
        icon: <MenuMBAdapter />,
        name:'adapter',
        module_id:10009,


    }
]



export {
    menu, submenu
}