import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import style from './index.module.less'
import * as yup from "yup"
import { PulseLoader } from "react-spinners"
import ReactGA from "react-ga"
import { useGTM } from '@/hooks/useGTM.js'
import { footer_email_list } from '@/utils/regions.js'
import getCountry from '@/utils/getCountry.js'

//邮箱验证
const emailSchema = yup
    .string()
    .email()
    .required()
const EmailForm = () => {
    const intl = useIntl()
    const [btnChange, setBtnChange] = useState(false)
    const [status, setStatus] = React.useState('edit')
    const [buttonDisabled, setButtondis] = React.useState(false)
    const formSubscribed = intl.formatMessage({ id: "footerpage.subscribed" })
    const formSubmit = intl.formatMessage({ id: "footerpage.subscribe" })
    const [validate, setValidate] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const inputBox = useRef()
    const region = getCountry()

    const inputChange = (e) => {
        setInputValue(e.target.value)
    }

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (inputBox.current && inputBox.current.contains(e.target)) {
                setBtnChange(true)
            } else {
                setBtnChange(false)
            }
        })
    }, [])

    useEffect(() => {

        if (status == 'sending') {
            setButtondis(true)
        }
        if (status == 'success') {
            setButtondis(true)
            useGTM('subscribe_success','','','footer navigation')

        }
    }, [status])
    const submit = async () => {
        useGTM('navigation_click','SUBSCRIBE','','footer navigation')

        if (buttonDisabled) {
            return
        }
        setStatus('sending')
        const isValid = await emailSchema.isValid(inputValue)
        if (!isValid) {
            setValidate(false)
            setStatus('edit')
            setButtondis(false)
            return
        }
        setValidate(true)
        ReactGA.event({
            category: 'Footer',
            action: 'Click',
            label: "footer_email_subscribe",
            value: 1,
            nonInteraction: true
        });

        var formdata = new FormData();
        formdata.append("email", inputValue);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            Headers: {
                'Access-Control-Allow-Origin': "*"
            },
            mode: "no-cors"
        };

        fetch(footer_email_list[region], requestOptions)
            .then(response => {
                response.text(); setStatus('success')

            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }


    return (
        <div className={style.formBox} >
            <div className={style.formBoxTitle} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "footerpage.text1", defaultMessage: "Keep in touch with &nbsp;<span> XREAL</span>" })}` }} ></div>
            <div className={style.formBoxInput}
                ref={inputBox}
            >
                <input
                    placeholder={intl.formatMessage({ id: "email_text" })}
                    onChange={(e) => inputChange(e)}
                    value={inputValue}

                />
                {btnChange &&
                    (<button onClick={submit} style={{ color: status == 'success' ? 'rgba(0,0,0,0.2)' : "#FF2929", cursor: status == 'success' ? 'auto' : "pointer" }}
                    > {status == 'edit' && formSubmit}
                        {status == 'sending' && <PulseLoader
                            sizeUnit="px"
                            size={6}
                            color={"#FF2929"}
                        />
                        }
                        {status == 'success' && formSubscribed}
                    </button>)
                }
                {!validate && btnChange && <div className={style.error}>{intl.formatMessage({ id: "form.requireEmail" })}</div>}
            </div>

        </div>
    )
}

export default EmailForm