import React, { useState, useEffect } from "react"
import style from "./style.module.less"
import SwitchQuestion from './switch'
import MultipleQuestion from './multiple'
import useGa from '@/hooks/useGa'
import SuccessIcon from '@/images/SVG/success.svg'
import CloseIcon from '@/images/SVG/close.svg'

function Survey(props) {
  const { surveyData, surveyCommit, frequency } = props;
  const [hideSurvey, setHideSurvey] = useState(false);
  const [qsId, setQsId] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [qsAnswers, setQsAnswers] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const QS_NEVER_SHOW = 'never';

  useEffect(() => {
    getSurvey();
  }, [])

  const getSurvey = () => {
    const { content, id } = surveyData;
    setQsId(id);
    const qs = content.questions;
    if (!qs || qs.length === 0) return;
    // 问题按 order 排序
    const orderedQs = qs.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
    // console.log('问题按 order 排序', orderedQs)
    setQuestions(orderedQs);
  }

  // 根据frequency获取下次弹出时间
  const getNextTime = () => {
    let nextTime = QS_NEVER_SHOW;
    if (!frequency.once) {
      const nextDay = parseInt(frequency.days);
      const currentDate = new Date();
      const nDaysLater = new Date(currentDate);
      nDaysLater.setDate(currentDate.getDate() + nextDay);
      nextTime = nDaysLater.getTime();
      // console.log(currentDate, nextDay, '问卷 nextTime', nextTime, new Date(nextTime));
    } else {
      nextTime = QS_NEVER_SHOW;
    }
    return nextTime;
  }

  // 提交问卷(不是主动提交:默认false)
  const onSurveySubmit = ({ isSuspended = false }) => {
    if (hasError) return;
    useGa("survey", isSuspended ? "survey_close" : "survey_submit", "Click");
    const nextTime = getNextTime();
    if (isSuspended && (!qsAnswers || qsAnswers.length < 1)) {
      // 这部分判断目前用不上，第一题不填，没有关闭按钮😅
      setHideSurvey(true);
      localStorage.setItem(`survey-next-time-${qsId}`, nextTime)
    } else {
      let currentPath = window.location.pathname;
      if (currentPath.length > 1 && currentPath.endsWith('/')) {
        currentPath = currentPath.slice(0, -1);
      }
      const answerParams = {
        questionnaireTemplateId: qsId,
        isSuspended: isSuspended,
        channel: 'web',
        channelKey: currentPath,
        content: {
          title: surveyData.content.title,
          questions: qsAnswers
        }
      }
      surveyCommit(answerParams);
      setHideSurvey(true);
      !isSuspended && setShowSuccess(true);
      localStorage.setItem(`survey-next-time-${qsId}`, isSuspended ? nextTime : QS_NEVER_SHOW)
    }
  }
  const reportError = (bool) => {
    setHasError(bool);
  }

  const onChoose = ({ order, name, value, showOrder = [], hideOrder = [] }) => {
    // 更新答案数组
    let newQsAnswers = JSON.parse(JSON.stringify(qsAnswers));
    const indexToRemove = newQsAnswers.findIndex(item => item.order == order);
    if (indexToRemove !== -1) {
      newQsAnswers.splice(indexToRemove, 1);
    }
    const answerItem = { order, name, value };
    newQsAnswers.push(answerItem);
    setQsAnswers(newQsAnswers);

    // 更新问题数组
    let newQs = JSON.parse(JSON.stringify(questions));
    newQs.map(item => {
      const showOrderFix = showOrder?.map(str => Number(str));
      if (showOrderFix?.includes(item.order)) {
        item.hidden = false;
      }
      const hideOrderFix = hideOrder?.map(str => Number(str));
      if (hideOrderFix?.includes(item.order)) {
        item.hidden = true;
        // item.value = [];
      }
      if (item.order == order) {
        item.value = value;
      }
    })
    setQuestions(newQs);
  }

  const SuccessTip = () => {
    return <div className={style.survey} style={!showSuccess ? { height: 0, bottom: '-1000px' } : {}}>
      <div className={style.surveyTop}>
        <div></div>
        <div className={style.close} onClick={() => setShowSuccess(false)}>
          <CloseIcon style={{ width: 24, height: 24 }} />
        </div>
      </div>
      <div className={style.surveySuccess}>
        <SuccessIcon />
        <p>Submitted successfully</p>
      </div>
    </div>
  }

  if (!questions || questions?.length === 0) return <></>
  if (showSuccess) return <SuccessTip />
  const isFirstAnswered = qsAnswers?.some(item => item.order == 0)
  return (
    <div className={style.survey} style={hideSurvey ? { height: 0, bottom: '-1000px' } : {}}>
      {
        isFirstAnswered && <div className={style.surveyTop}>
          {questions[0].type === "SWITCH" ? <SwitchQuestion qsItem={questions[0]} isMini={true} onChoose={onChoose} /> : <div></div>}
          <div className={style.close} onClick={() => onSurveySubmit({ isSuspended: true })}>
            <CloseIcon style={{ width: 24, height: 24 }} />
          </div>
        </div>
      }
      <div className={style.surveyMain}>
        <div className={style.questions}>
          {
            questions.map((item, _) => {
              let questionCell = <></>;
              switch (item.type) {
                case "SWITCH":
                  questionCell = !isFirstAnswered ? <SwitchQuestion qsItem={item} onChoose={onChoose} /> : <></>;
                  break;
                case "MULTIPLE":
                  questionCell = <MultipleQuestion qsItem={item} onChoose={onChoose} reportError={reportError} />;
                  break;
                default:
                  questionCell = <></>;
                  break;
              }
              if (item.hidden) return <div key={item.name}></div>
              return <div key={item.name}>
                {questionCell}
              </div>
            })
          }
        </div>
        {isFirstAnswered && <button className={style.submit} onClick={onSurveySubmit}>Submit</button>}
      </div>
    </div>
  )
}

export default Survey
