import getCountry from '@/utils/getCountry'

function useGTMScroll(percent) {

    window.dataLayer.push({
        event: "uaEvent",
        event_name: "page_scroll",
        event_parameters: {
            scroll_percent: percent
        }
    });
}

function useGTM(event_name, button, general_title, module) {
        window.dataLayer.push({
            event: "uaEvent",
            event_name: event_name,
            event_parameters: {
                button: button,
                general_title: general_title,
                module: module
            }
        });


}

export { useGTMScroll, useGTM } 