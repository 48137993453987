import React, { useState, useEffect } from "react"
import "./layout.css"
import VideoCom from "./VideoCom";
import Header from "../new_components/header"
import Footer from "../new_components/footer"
import OldHeader from "./Header/index"
import OldFooter from './Footer/index'
import CookieBanner from "./Footer/CookieBanner"
import NoticeBanner from "./Header/NoticeBanner"
import { Survey } from "@/new_components"
import { getQsMatch, getQsTemplate, qsCommit } from '@/services/request'
import useGa from '@/hooks/useGa'
import { debounce } from 'lodash'
import getCountry from '@/utils/getCountry.js'
import {useGTMScroll,useGTM} from '@/hooks/useGTM.js'


const QS_NEVER_SHOW = 'never';

const Layout = ({
  wholebg = false,
  contentBackground = {},
  showsubmenu = false,
  showMenu = true,
  menuBlack = false,
  showFooter = true,
  footerBackground = '#111', //footer默认黑色背景
  footerTextWhite = true, //footer文字颜色默认白色
  children,
  showNoticeBanner = false
}) => {
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [frequency, setFrequency] = useState({});
  const [scrollProgress, setScrollProgress] = useState(0);
  const region = getCountry()


  let udeskBtn = document.querySelector('#udesk_btn')


  const handleScroll = debounce(() => {
    calculateScrollProgress();
  }, 200);

  const calculateScrollProgress = () => {
    const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPosition = window.scrollY;
    const progress = (scrollPosition / totalHeight) * 100;

    if (progress > 25 && progress <= 50) {
      setScrollProgress((prevProgress) => {
        return Math.max(prevProgress, 0.25);
      });
    } else if (progress > 50 && progress <= 75) {
      setScrollProgress((prevProgress) => {
        return Math.max(prevProgress, 0.5);
      });
    } else if (progress > 75 && progress < 100) {
      setScrollProgress((prevProgress) => {
        return Math.max(prevProgress, 0.75);
      });
    } else if (progress == 100) {
      setScrollProgress((prevProgress) => {
        return Math.max(prevProgress, 1);
      });
    }
  };

  useEffect(() => {
    if (scrollProgress !== 0) {
      typeof (sensors) !== "undefined" && sensors.track('page_view', {
        custom: {
          page_ratio: scrollProgress
        }
      });

      useGTMScroll(scrollProgress)
    }
  }, [scrollProgress])

  useEffect(() => {
    //监听页面浏览比例的
    const specialPaths = ['/beampro','/air2ultra','/air2', '/air', '/beam', '/adapter'];
    const isSpecialPage = specialPaths.some(path => window.location.pathname === '/' || window.location.pathname.includes(path));
    if (!!isSpecialPage) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getQsMatchInfo();
  }, [])
  // udesk点击埋点
  useEffect(() => {
    window.addEventListener("click", clickUdesk)

    return ()=>{
      window.removeEventListener('click',clickUdesk)
    }

  }, [])

  const clickUdesk=(e)=>{
    if (e.target.className == 'buttonImg') {
      typeof sensors !== "undefined" && sensors.track('module_interaction', {
        custom: {
          module_id: 10006
        }
      });

      useGTM('navigation_click','customer service','','floating')
      
    }
  }

  // 获取问卷投放信息
  const getQsMatchInfo = () => {
    let currentPath = window.location.pathname;
    if (currentPath.length > 1 && currentPath.endsWith('/')) {
      currentPath = currentPath.slice(0, -1);
    }
    const params = { channel: 'web', channelKey: currentPath }
    getQsMatch(params).then(res => {
      const { data, code } = res.data;
      if (code == 200 && data.length > 0) {
        const firstQuestionnaire = data[0];
        if (!!firstQuestionnaire?.questionnaireTemplateId) {
          // 有问卷，看本地存储的问卷信息
          const qsId = firstQuestionnaire.questionnaireTemplateId;
          // // ------跳过逻辑，直接显示 start------------
          // getQsTempInfo(qsId);
          // setShowSurvey(true);
          // return;
          // // ------跳过逻辑，直接显示 end--------------
          const localSurveyNextTime = localStorage.getItem(`survey-next-time-${qsId}`);
          if (!localSurveyNextTime) {
            // 本地没有问卷下次弹出时间，获取问卷，正常处理Trigger
            getQsTempInfo(qsId);
            handleQsTrigger(firstQuestionnaire.trigger, qsId);
            useGa("survey", "survey_impressions", "Click");
          } else {
            // 本地有问卷下次弹出时间
            const currentTimestamp = new Date().getTime();
            if (localSurveyNextTime !== QS_NEVER_SHOW && Number(localSurveyNextTime) <= currentTimestamp) {
              // 弹出时间在当前时间之前，获取问卷，正常处理Trigger
              getQsTempInfo(qsId);
              handleQsTrigger(firstQuestionnaire.trigger);
              useGa("survey", "survey_impressions", "Click");
            } else {
              // 弹出时间是 QS_NEVER_SHOW(9) 或 在当前时间之后，不弹出
              setShowSurvey(false);
            }
          }
        } else {
          setShowSurvey(false);
        }
      } else {
        setShowSurvey(false);
      }
    }).catch(error => {
      console.error('getQuestionnaireMatch Error:', error);
    });
  }

  // 处理问卷trigger
  const handleQsTrigger = (trigger) => {
    const { rule, frequency } = trigger;
    if (rule.type === 'delay') {
      setTimeout(() => {
        setShowSurvey(true)
      }, rule.time);
    }
    setFrequency(frequency);
  }

  // 获取问卷模板
  const getQsTempInfo = (id) => {
    getQsTemplate({ id }).then(res => {
      const { data, code } = res.data;
      if (code == 200) {
        setSurveyData(data)
      }
    }).catch(error => {
      console.error('getQsTempInfo Error:', error);
    });
  }

  // 提交问卷
  const surveyCommit = (answer) => {
    console.log('提交问卷', answer)
    const params = answer;
    qsCommit(params).then(res => {
      const { data, code } = res.data;
      if (code == 200) {
        // 提交成功
      }
    }).catch(error => {
      console.error('surveyCommit Error:', error);
    });
  }
  // console.log('显示问卷', showSurvey)


  return (
    <>

      {showMenu && (
        region=='th'?<OldHeader  menuBlack={menuBlack}
        showsubmenu={showsubmenu}/>:
        <Header
          menuBlack={menuBlack}
          showsubmenu={showsubmenu}
        />
      )}
      {showNoticeBanner && <NoticeBanner />}
      {wholebg && <div className='contentBg'>
        {contentBackground.type == 'img' &&
          <div style={{ backgroundImage: `url(${contentBackground.content})`, backgroundSize: "cover", backgroundPosition: "center" }} />}
        {contentBackground.type == 'video' &&
          <VideoCom src={contentBackground.content} />
        }
        {contentBackground.type == 'color' &&
          <div style={{ background: contentBackground.content }} />
        }
      </div>}
      <div className={`content`} >
        {children}
      </div>
      {showFooter && (
        region=='th'?<OldFooter  footerBackground={footerBackground} footerTextWhite={footerTextWhite}/>:
        <Footer
          footerBackground={footerBackground} footerTextWhite={footerTextWhite}
        />
      )}
      <CookieBanner />
      {
        showSurvey && !!surveyData &&
        <Survey surveyCommit={surveyCommit} surveyData={surveyData} frequency={frequency} />
      }
    </>
  )
}


export default Layout

