import React from "react";
import style from "./index.module.less"
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl";
import useGa from "@/hooks/useGa"
import getCountry from '@/utils/getCountry.js'


const FooterList = () => {
    const intl = useIntl()
    const region = getCountry()
    const footerlist_normal = [
        {
            title: intl.formatMessage({ id: "footerList.about", defaultMessage: "About" }),
            outbound: false,
            link: "/about",
            ga: "footer_about",
            list: [
                // {
                //     title:intl.formatMessage({ id: "footerList.news", defaultMessage: "News" }),
                //     outbound:false,
                //     link:"/news",
                //     ga:"footer_news",
                // },
                {
                    title: intl.formatMessage({ id: "footerList.contact", defaultMessage: 'Contact Us' }),
                    outbound: false,
                    link: "/contact-us",
                    ga: "footer_contact_us",
                },
                {
                    title: intl.formatMessage({ id: "footerList.join", defaultMessage: 'Join Us' }),
                    outbound: false,
                    link: "/career",
                    ga: "footer_join_us",
                },
                {
                    title:region=='base'||region=='jp'||region=='us'?intl.formatMessage({ id: "legacy_store", defaultMessage: 'Legacy Store' }):'',
                    outbound: true,
                    link: region=='base'||region=='us'?"https://www.xreal.com/shop/customer/account/":"https://www.xreal.com/jp/shop/customer/account/",
                    ga: "footer_legacy_store",
                }
            ]
        },
        {
            title: intl.formatMessage({ id: "footerList.developer", defaultMessage: 'Developer' }),
            outbound: true,
            link: process.env.url_developer,
            ga: "footer_developer",
            list: [
                {
                    title: intl.formatMessage({ id: "footerList.sdk_download", defaultMessage: 'SDK Download' }),
                    outbound: true,
                    link: `${process.env.url_developer}/download`,
                    ga: "footer_SDK",
                },
                {
                    title: intl.formatMessage({ id: "footerList.community", defaultMessage: 'Community' }),
                    outbound: true,
                    link: process.env.url_community,
                    ga: "footer_community",
                },
                {
                    title: intl.formatMessage({ id: "footerList.discord", defaultMessage: 'Discord' }),
                    outbound: true,
                    link: intl.locale == 'ja' ? process.env.url_discord_jp : process.env.url_discord,
                    ga: "footer_discord",
                }
            ]
        }
    ]
    const footerlist_jp=[
        {
            title: intl.formatMessage({ id: "footerList.about", defaultMessage: "About" }),
            outbound: false,
            link: "/about",
            ga: "footer_about",
            list: [
                // {
                //     title:intl.formatMessage({ id: "footerList.news", defaultMessage: "News" }),
                //     outbound:false,
                //     link:"/news",
                //     ga:"footer_news",
                // },
                {
                    title: intl.formatMessage({ id: "footerList.contact", defaultMessage: 'Contact Us' }),
                    outbound: false,
                    link: "/contact-us",
                    ga: "footer_contact_us",
                },
                {
                    title: intl.formatMessage({ id: "footerList.join", defaultMessage: 'Join Us' }),
                    outbound: false,
                    link: "/career",
                    ga: "footer_join_us",
                },
                {
                    title: '体験店舗リスト',
                    outbound: false,
                    link: "/air/stores",
                    ga: "footer_jp_stores",
                },
                {
                    title:region=='base'||region=='jp'||region=='us'?intl.formatMessage({ id: "legacy_store", defaultMessage: 'Legacy Store' }):'',
                    outbound: true,
                    link: region=='base'||region=='us'?"https://www.xreal.com/shop/customer/account/":"https://www.xreal.com/jp/shop/customer/account/",
                    ga: "footer_legacy_store",
                }
            ]
        },
        {
            title: intl.formatMessage({ id: "footerList.developer", defaultMessage: 'Developer' }),
            outbound: true,
            link: process.env.url_developer,
            ga: "footer_developer",
            list: [
                {
                    title: intl.formatMessage({ id: "footerList.sdk_download", defaultMessage: 'SDK Download' }),
                    outbound: true,
                    link: `${process.env.url_developer}/download`,
                    ga: "footer_SDK",
                },
                {
                    title: intl.formatMessage({ id: "footerList.community", defaultMessage: 'Community' }),
                    outbound: true,
                    link: process.env.url_community,
                    ga: "footer_community",
                },
                {
                    title: intl.formatMessage({ id: "footerList.discord", defaultMessage: 'Discord' }),
                    outbound: true,
                    link: intl.locale == 'ja' ? process.env.url_discord_jp : process.env.url_discord,
                    ga: "footer_discord",
                }
            ]
        }
    ]
    const footerlist = region=='jp'?footerlist_jp:footerlist_normal
    const goLink = (out, link) => {
        if (out) {
            window.open(link)
        } else {
            useNavigate(link)
        }
    }
    return (
        <div className={style.footerList}>
            {footerlist.map(item => (
                <div key={`footer${item.title}`} className={style.footerListBox}>
                    <div className={style.footerListBoxTitle} onClick={() => goLink(item.outbound, item.link)}>{item.title}</div>
                    <div className={style.footerListContent}>
                        {item.list.map(value => (
                            <div className={style.footerListContentItem} key={`footerList${value.title}`}
                                onClick={() => {
                                    useGa('footer', value.ga, 'Click')
                                    goLink(value.outbound, value.link)
                                }}
                            >
                                {value.title}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>)
}

export default FooterList