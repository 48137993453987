import React ,{useRef,useEffect,useState}from 'react'
import style from './index.module.less'
import { Link } from "gatsby"
import {
    XrealLogo
} from "@/images/menu"
import CheckIpBox from './checkip.js'
import CartSVG from "@/images/SVG/cart.svg"
import ShopIconSVG from "@/images/SVG/shopicon.svg"
import useGa from '@/hooks/useGa'
import classNames from 'classnames'
import { submenu_prolist,submenu_prolist_kr } from './const_menulist.js'
import { useIntl } from "react-intl";
import getCountry from '@/utils/getCountry.js'
import { useGTM } from '@/hooks/useGTM.js'

const MobileMenu = ({ ifSubMenuShow, setIfSubMenuShow, getLinkUrl, shopUrl }) => {
    const intl = useIntl()
    const region = getCountry()
    const menu_list=region=='kr'?submenu_prolist_kr:submenu_prolist
    const menuRef = useRef(null)
    const headerRef = useRef(null)

    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id, product_name) => {
        if (!!product_name) {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id,
                    products_name: product_name
                }
            });
        } else {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id
                }
            });
        }

    }


    useEffect(() => {
        document.body.style.overflow = 'auto'
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    let lastScrollTop = 0;
    const handleScroll = () => {
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;
        const whentohide = 200
        if (scrollDistance > 0 && currentScrollTop >= whentohide) {
            menuRef.current.style.opacity = '0'
            headerRef.current.style.transform = `translateY(-100%)`
        } else {
            menuRef.current.style.opacity = '1'
            headerRef.current.style.transform = `translateY(0)`
        }
    }
    return (
        <div className={style.header} ref={headerRef}>
            <CheckIpBox ifPc={true} />
            <div className={style.menuM} style={{ backgroundColor: ifSubMenuShow ? '#fff' : "transparent" }} ref={menuRef}>
                <div className={classNames(style.menuMBtn, ifSubMenuShow && style.menuMBtnClose)}
                    onClick={() =>{ setIfSubMenuShow(!ifSubMenuShow),
                        useGTM('navigation_click','menu','','top navigation')
                    }}
                >
                    <div className={classNames(style.menuMBtnItem, style.menuMBtnItem1)}></div>
                    <div className={classNames(style.menuMBtnItem, style.menuMBtnItem2)}></div>
                    <div className={classNames(style.menuMBtnItem, style.menuMBtnItem3)}></div>
                </div>
                <Link to={getLinkUrl("/")} className={style.menuLogo} onClick={() => { addGa('menu_home'), addSensor(10000),  useGTM('navigation_click','XREAL','','top navigation') }}>
                    <XrealLogo />
                </Link>
                <a href={shopUrl} target='_blank' className={style.cart} onClick={() => addGa('menu_shop', addSensor(10004),useGTM('navigation_click','SHOP','','top navigation') )}>
                    <ShopIconSVG />
                    <span> {intl.formatMessage({ id: "menu_shop" })}</span>
                </a>
            </div>
            <div className={style.menuMList} style={{ height: ifSubMenuShow ? 'calc(100vh - 48px)' : '0', opacity: ifSubMenuShow ? '1' : '0' }}>
                <div className={style.menuMTitle}>
                {intl.formatMessage({ id: "menu.products" })}
                </div>
                <div className={style.menuMPro}>
                    {menu_list.map((item, index) => (
                        <div key={`menuMPro${index}`} className={style.menuMProItem}>
                            <div className={style.menuMProItemType}>
                            {intl.formatMessage({ id: item.type})}
                           
                            </div>
                            <div>
                                {item.pro.map((value) => (
                                    <div className={style.menuMProItemName} key={`menuMpro${index}-${value.name}`}
                                    onClick={()=>useGTM('navigation_click',value.name,'','top navigation') }
                                    >
                                        {!!value.outlink?<a href={value.link} target='_blank'>
                                            {value.name}
                                        </a>:
                                          <Link to={getLinkUrl(value.link)}

                                          onClick={() => { addGa(value.gaID), addSensor(value.sensorID) }}
                                      >
                                          {value.name}
                                      </Link>
                                        }
                                      
                                    </div>

                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={style.menuMElse}>
                    <Link to={getLinkUrl("/ar")} onClick={() => { addGa('menu_ar'), addSensor(10002),useGTM('navigation_click','AR','','top navigation')  }}>
                    {intl.formatMessage({ id: "menu.ar" })}
                    </Link>
                </div>
                <div className={style.menuMElse}>
                    <Link to={getLinkUrl("/support")} onClick={() => { addGa('menu_support'), addSensor(10003) ,useGTM('navigation_click','SUPPORT','','top navigation') }}>
                    {intl.formatMessage({ id: "menu.support" })}
                </Link>
                </div>
                
            </div>
        </div>
    )
}

export default MobileMenu