import React, { useRef, useEffect, useState } from "react";
import style from "./index.module.less"
import { Link } from "gatsby"
import {
    XrealLogo, MobileClose1, MobileOpen, ExpandLogo
} from "@/images/menu"
import { useIntl } from 'react-intl';
import useGa from '@/hooks/useGa'
import getCountry from '@/utils/getCountry.js'
import CheckIpBox from '@/new_components/header/checkip.js'
import home_buyBox from '@/images/home/home_buyBox.svg'

const MobileMenuModal = ({ showModal, getLinkUrl, menu, submenu }) => {

    const intl = useIntl()
    const modalRef = useRef(null)
    useEffect(() => {
        !showModal ? modalRef.current.style.height = "1px" : modalRef.current.style.height = "100vh"
        !showModal ? modalRef.current.style.opacity = "0" : modalRef.current.style.opacity = "1"
    }, [showModal])
    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id, product_name) => {
        if (!!product_name) {
            typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                custom: {
                    module_id: id,
                    products_name: product_name
                }
            });
        } else {
            typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                custom: {
                    module_id: id
                }
            });
        }

    }
    const expandRef = useRef(null)
    const expandSubRef = useRef(null)
    const [expand, setexpand] = useState(false)
    useEffect(() => {
        if (expandRef.current) {
            if (expand) {
                expandRef.current.style.transform = "rotateX(180deg)"
                expandSubRef.current.style.height = "80px"
                expandSubRef.current.style.margin = "-20px 0 40px"
            } else {
                expandRef.current.style.transform = "rotateX(0deg)"
                expandSubRef.current.style.height = "0px"
                expandSubRef.current.style.margin = "0"
            }
        }
    }, [expand])

    return <div className={style.modal} ref={modalRef}  >
        <div className={style.modalLink} >
            {menu.map((item, idx) => {
                return (
                    <div key={idx} className={style.modalLinkItem}>
                        {item.title === 'Products' && (<div className={style.modalLinkItemMenu} onClick={() => {
                            addSensor(item.module_id),
                                setexpand(!expand);
                        }}>
                            <Link >
                                {intl.formatMessage({ id: item.id, defaultMessage: item.title })}
                            </Link>
                            <div className={style.expand} ref={expandRef}>
                                <ExpandLogo />
                            </div>
                        </div>)}
                        {item.title !== 'Products' && <div className={style.modalLinkItemMenu}>
                            <Link to={getLinkUrl(item.link)} onClick={() => { addGa(item.id), addSensor(item.module_id) }}>
                                {intl.formatMessage({ id: item.id, defaultMessage: item.title })}
                            </Link>
                        </div>}

                        {item.title === "Products" && (
                            <div className={style.modalLinkItemMenu} ref={expandSubRef}>
                                {submenu.map((subitem, subIdx) => {
                                    return (
                                        <Link key={subIdx} to={getLinkUrl(subitem.link)} onClick={() => { addGa(subitem.id), addSensor(item.module_id, item.name) }}>
                                            {subitem.icon}
                                        </Link>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    </div>
}




const MobileMenu = ({ getLinkUrl, shopUrl, menu, submenu }) => {
    const intl = useIntl()
    const region = getCountry()
    let mbheaderRef = useRef(null)
    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id) => {
        typeof sensors !== "undefined" &&   sensors.track('module_interaction', {
            custom: {
                module_id: id
            }
        });
    }
    let lastScrollTop = 0;
    const handleScroll = () => {
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;
        const whentohide = 60
        if (scrollDistance > 0 && currentScrollTop >= whentohide) {
            mbheaderRef.current.style.transform = `translateY(-100%)`
        } else {
            mbheaderRef.current.style.transform = `translateY(0)`
        }
    }


    const [showModal, setshowModal] = useState(false)
    return <>

        <div className={`${style.mb_header} ${showModal ? style.sameasmodal : ""}`} ref={mbheaderRef} style={{ borderBottom: showModal ? "none" : "1px solid var(--menu-borderColor)" }}>
            <CheckIpBox ifPc={false} />
            <div className={style.mb_headerBox}>
                <div className={style.mb_left}>
                    <div className={style.mb_leftIcon} onClick={() => { document.body.style.overflow = `${showModal ? 'auto' : 'hidden'}`; setshowModal(!showModal); }}>
                        {!showModal && <MobileOpen />}
                        {showModal && <MobileClose1 />}
                    </div>
                    <Link to={getLinkUrl("/")} className={style.mb_logo} onClick={() => { addGa('menu_home'), addSensor(10000) }}>
                        <XrealLogo />
                    </Link>
                </div>

                <div  className={style.mb_right} onClick={() => { addGa('menu_shop'), addSensor(10004) }}>
                    {intl.formatMessage({ id: "shop_text", defaultMessage: "Shop" })}

                    <div className={style.buyBox}>
                                <img src={home_buyBox} className={style.buyBoxBg}/>
                                <div className={style.buyBoxBtn} onClick={() => window.open('https://shopee.co.th/xrealofficialstore')}>
                                    <span>SHOPEE</span>
                                </div>
                                <div className={style.buyBoxBtn} onClick={() => window.open('https://www.lazada.co.th/shop/xrealofficialstore')}>
                                    <span>LAZADA</span>
                                </div>
                            </div>
                </div>
            </div>





            <MobileMenuModal showModal={showModal} getLinkUrl={getLinkUrl} menu={menu} submenu={submenu} />

        </div>

    </>

}


export default MobileMenu