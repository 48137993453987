import XrealLogo from './SVG/xreal.svg'
import UserLogo from './SVG/user.svg'
import ExpandLogo from './SVG/expand.svg'
import MenuMBAir2 from './SVG/menu_mb_air2.svg'
import MenuMBAdapter from './SVG/menu_mb_adapter.svg'
import MenuMBAir from './SVG/menu_mb_air.svg'
import MenuMBBeam from './SVG/menu_mb_beam.svg'
import AirLogo from './SVG/air.svg'
import Air2Logo from './SVG/Air2Logo.svg'
import Air2UltraLogo from './SVG/air2ultra.svg'
import LightLogo from './SVG/light.svg'
import BeamLogo from './SVG/beam.svg'
import AdapterLogo from './SVG/adapter.svg'
import MobileOpen from './SVG/mobileOpen.svg'
import mobileClose from './mobileClose.svg'
import MobileClose1 from './SVG/mobileClose.svg'
import mobileSubClose from './mobileSubClose.svg'

const menu_ultra= `${process.env.resourceUrl}images/menu/menu-ultra.png`
const menu_beampro= `${process.env.resourceUrl}images/menu/menu-beampro.png`


export {
    Air2UltraLogo,
    menu_ultra,
    menu_beampro,
    Air2Logo,

    XrealLogo,
    UserLogo,
    ExpandLogo,
    MenuMBAir2,
    MenuMBAdapter,
    MenuMBAir,
    MenuMBBeam,
    AirLogo,
    LightLogo,
    BeamLogo,
    AdapterLogo,
    mobileClose,
    MobileClose1,
    MobileOpen,
    mobileSubClose
}