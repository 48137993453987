import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import style from './index.module.less'
import * as yup from "yup"
import { PulseLoader } from "react-spinners"
import ReactGA from "react-ga"
import {
    reddit,
    ins,
    fb,
    tw,
    insB,
    fbB,
    twB,
    redditB,
    youtube,
    youtubeB,
} from '@/images/index'

//facebook twitter 图标
const Icon = ({ footerTextWhite }) => {
    const intl = useIntl()
    const icons = [
        { src: footerTextWhite ? tw : twB, link: process.env.url_twitter, ga: "footer_twitter" },
        { src: footerTextWhite ? ins : insB, link: process.env.url_ins, ga: "footer_instagram" },
        { src: footerTextWhite ? reddit : redditB, link: process.env.url_reddit, ga: "footer_reddit" }
    ]
    const icons1 = [
        { src: footerTextWhite ? tw : twB, link: process.env.url_twitter_jp, ga: "footer_twitter_jp" },
        { src: footerTextWhite ? youtube : youtubeB, link: process.env.url_youtube_jp, ga: "footer_youtube_jp" },
        { src: footerTextWhite ? ins : insB, link: process.env.url_ins_jp, ga: "footer_instagram" },
        // { src: footerTextWhite?reddit:redditB, link: process.env.url_reddit, ga: "footer_reddit" }

    ]
    const [iconsAry, setIconsAry] = React.useState(icons)

    React.useEffect(function () {
        if (intl.locale === 'ja') {
            setIconsAry(icons1)
        } else {
            setIconsAry(icons)
        }
    }, intl.locale)
    return (<div className={style.icon} >
        {iconsAry.map(item => (
            <a href={item.link} key={item.link} target="_blank"  >
                <img
                    src={item.src}
                    alt={item.link}
                />
            </a>
        ))}
    </div>)
}
//邮箱验证
const emailSchema = yup
    .string()
    .email()
    .required()
const EmailForm = ({ footerTextWhite }) => {
    const intl = useIntl()
    const [btnChange, setBtnChange] = useState(false)
    const [status, setStatus] = React.useState('edit')
    const [buttonDisabled, setButtondis] = React.useState(false)
    const formSubscribed = intl.formatMessage({ id: "footerpage.subscribed" })
    const formSubmit = intl.formatMessage({ id: "footerpage.subscribe" })
    const [validate, setValidate] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const inputBox = useRef()
    const inputChange = (e) => {
        setInputValue(e.target.value)
    }

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (inputBox.current && inputBox.current.contains(e.target)) {
                setBtnChange(true)
            } else {
                setBtnChange(false)
            }
        })
    }, [])

    useEffect(() => {

        if (status == 'sending') {
            setButtondis(true)
        }
        if (status == 'success') {
            setButtondis(true)

        }
    }, [status])
    const submit = async () => {
        if (buttonDisabled) {
            return
        }
        setStatus('sending')
        const isValid = await emailSchema.isValid(inputValue)
        if (!isValid) {
            setValidate(false)
            setStatus('edit')
            setButtondis(false)
            return
        }
        setValidate(true)
        ReactGA.event({
            category: 'Footer',
            action: 'Click',
            label: "footer_email_subscribe",
            value: 1,
            nonInteraction: true
        });

        var formdata = new FormData();
        formdata.append("email", inputValue);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            Headers: {
                'Access-Control-Allow-Origin': "*"
            },
            mode: "no-cors"
        };

        fetch("https://manage.kmail-lists.com/subscriptions/subscribe?a=WQdKRF&g=SAxNMY", requestOptions)
            .then(response => {
                response.text(); setStatus('success')

            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }


    return (
        <div className={style.formBox} >
            <div className={style.formBoxTitle}>{intl.formatMessage({ id: "footerpage.text1", defaultMessage: "Keep in touch with XREAL" })}</div>
            <div className={style.formBoxInput}
                ref={inputBox}
                style={{ borderBottom: btnChange ? '2px solid #ff3300' : "2px solid var(--footer-opacity1)" }}>
                <input
                    placeholder={intl.formatMessage({ id: "email_text" })}
                    onChange={(e) => inputChange(e)}
                    value={inputValue}

                />
                {btnChange &&
                    (<button onClick={submit} style={{ color: status == 'success' ? '#ff3300' : "var(--footer-opacity1)", cursor: status == 'success' ? 'auto' : "pointer" }}
                    > {status == 'edit' && formSubmit}
                        {status == 'sending' && <PulseLoader
                            sizeUnit="px"
                            size={6}
                            color={"var(--footer-opacity1)"}
                        />
                        }
                        {status == 'success' && formSubscribed}
                    </button>)
                }
                {!validate && btnChange && <div className={style.error}>{intl.formatMessage({ id: "form.requireEmail" })}</div>}
            </div>

            <Icon footerTextWhite={footerTextWhite} />
        </div>
    )
}

export default EmailForm