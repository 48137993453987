// 多选题
import React, { useState, useEffect } from "react"
import style from "./style.module.less"

function MultipleQuestion(props) {
  const { qsItem, onChoose, reportError } = props;
  const [qsValue, setQsValue] = useState(qsItem?.value || []);
  const [otherText, setOtherText] = useState('');
  const [isOverflow, setIsOverflow] = useState(false);
  const [isOtherTextOver, setOtherTextOver] = useState(false);

  const overflowTip = 'Select up to 3 options';
  const otherTextMaxLength = 1000 + 1;

  useEffect(() => {
    if (isOverflow) {
      setTimeout(() => {
        setIsOverflow(false)
      }, 2000);
    }
  }, [isOverflow])

  const SelectCell = (props) => {
    const { cellItem, isSelect, onClick } = props;
    return <div className={style.multipleOptionsCell} style={isSelect ? { borderColor: '#333' } : {}} onClick={onClick}>
      {cellItem.label}
    </div>
  }

  const onAnswerChange = (val) => {
    let newVal = JSON.parse(JSON.stringify(qsValue));
    // 「当前选中答案」在「答案列表」中的index
    const currentIndex = newVal.findIndex(item => item.id === val.id);
    // TODO: 方法可优化，合并判断
    if (val.id === `${qsItem.order}_other`) {
      if (currentIndex !== -1) {
        if (!!val.label && val.label != '') {
          newVal[currentIndex].label = val.label;
        } else {
          newVal.splice(currentIndex, 1);
          setIsOverflow(false);
        }
      } else {
        if (newVal?.length < 3) {
          newVal.push(val);
        } else {
          setIsOverflow(true);
          setOtherText('');
        }
      }
    } else {
      if (currentIndex !== -1) {
        newVal.splice(currentIndex, 1);
        setIsOverflow(false)
      } else {
        if (newVal?.length < 3) {
          newVal.push(val);
        } else {
          setIsOverflow(true);
        }
      }
    }
    setQsValue(newVal);
    if (!!onChoose) onChoose({ order: qsItem.order, name: qsItem.name, value: newVal });
  }

  const onOtherChange = e => {
    const newVal = e.target.value;
    if (newVal?.length >= otherTextMaxLength) {
      if(!!reportError) reportError(true);
      setOtherTextOver(true);
      setOtherText(newVal);
    } else {
      if(!!reportError) reportError(false);
      if(isOtherTextOver) setOtherTextOver(false);
      setOtherText(newVal);
      const otherAnswer = { id: `${qsItem.order}_other`, label: newVal };
      onAnswerChange(otherAnswer);
    }
  }

  const showOther = !!qsItem?.otherOptions?.other;
  return <div className={style.multiple}>
    <div className={style.qsLabel}>{qsItem?.label}</div>
    <div className={style.multipleOptions}>
      <p className={style.multipleOptionsTip} style={isOverflow ? { height: 15, opacity: 1 } : {}}>{overflowTip}</p>
      {
        qsItem?.options.map((item, _) => {
          const isSelect = qsValue.some(val => val.id === item.id);
          return <SelectCell key={item.id} cellItem={item} isSelect={isSelect}  onClick={() => onAnswerChange(item)} />
        })
      }
      {
        showOther &&
        <div className={style.otherTextarea}>
          <textarea
            name="Other"
            placeholder={qsItem?.otherOptions?.placeholder || "Other (please specify):"}
            maxLength={otherTextMaxLength}
            value={otherText}
            onChange={onOtherChange}
            onClick={onOtherChange}
            style={{ borderColor: isOtherTextOver ? '#FF3A52' : '#fff' }}
          ></textarea>
          <p className={`${style.multipleOptionsTip} ${style.otherTextareaTip}`} style={isOtherTextOver ? { opacity: 1 } : {}}>{`${otherTextMaxLength}/${ otherTextMaxLength - 1}`}</p>
        </div>
      }
    </div>
  </div>
}

export default MultipleQuestion