import React from "react"
// @ts-ignore
import style from "./index.module.less"
// @ts-ignore


const VideoCom = ({src,poster}) => {
  return (
      <div className={style.videoCon}>
        <video autoPlay loop muted playsInline poster={poster}  width="100%" > <source src={src} type="video/mp4" />  </video>
      </div>
  )
}

export default VideoCom
