import React, { useContext } from "react";
import style from './index.module.less'
// import { LangStateContext } from "@/context/langContext"
import { useIntl } from "react-intl"
import classNames from "classnames";
import closeSVG from "@/images/icons/cookieClose.svg"

const NoticeBanner = () => {
  const intl = useIntl()

  // const lang = useContext(LangStateContext)
  const [shouldSeeNoticeBanner, toggleNoticeBanner] = React.useState(false)

  React.useEffect(() => {
    toggleNoticeBanner(localStorage.getItem("notice_banner_231018") !== "0")
  }, [toggleNoticeBanner])

  const onCloseClicked = React.useCallback(() => {
    toggleNoticeBanner(false)
    localStorage.setItem("notice_banner_231018", "0")
  }, [toggleNoticeBanner])

  return (
    shouldSeeNoticeBanner && (
      <div className={classNames(style.wrapper, 'noticeBox')}>

        <div className={style.textWrapper} >
          <div className={style.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "notice_title" }) }} ></div>
          <div className={style.text} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "notice_text" }) }} ></div>
          <div className={style.text} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "notice_text_thanks" }) }} ></div>
        </div>

        <img className={style.close} src={closeSVG} onClick={onCloseClicked} />

      </div>
    )
  )
}

export default NoticeBanner