import React, { useContext, useEffect, useState } from "react";
import style from './index.module.less'
import {
    LangSetStateContext,
} from "@/context/langContext"
import { useIntl } from "react-intl";
import useBigScreen from "@/hooks/bigScreen"
import { arrow_right, CloseIcon, FooterEarth } from '@/images/index'
import { regionAll ,FooterData} from "@/utils/regions";
import classNames from 'classnames'
import { useGTM } from '@/hooks/useGTM.js'

const LangList = ({ country ,ifMenu,goRegion}) => {
    const [selectedCountry, setSelectedCountry] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const langData = FooterData

    useEffect(() => {
        if(!!ifMenu){
            document.body.style.overflow = 'auto'
            let select = Object.values(langData).flat().filter(item => item.region == goRegion);
            setSelectedCountry(select[0]);
        }else{
            document.body.style.overflow = 'auto'
            let select = Object.values(langData).flat().filter(item => item.region == country);
            setSelectedCountry(select[0]);
        }
      
    }, [goRegion])

    const changeLang = (region, lang) => {
       
        if(!!ifMenu){
            typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                custom: {
                    module_id: 20037,
                    country_name:region+'-'+lang
                }
            });
        }        
        const ifUrlHaveIp = regionAll.indexOf(window.location.pathname.split('/')[1]) > -1;
        let path = window.location.pathname
        if (ifUrlHaveIp) {
            path = `/${path.split('/').slice(2).join('/')}`;
        }
        if (region == 'base') {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.pathname = path
        } else if (region == 'cn') {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.href = `${process.env.url_cn}/${path.substring(1)}`
        }
        else {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.pathname = `/${region}${path}`
        }

        useGTM('navigation_click',region,'','footer navigation')

    }

    const toogleModal = (bool) => {
        if (bool) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        setModalShow(bool);
    }
    const addSensor = () =>{
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 10014
            }
        });
    }
    const SelectModal = () => (
        <div className={style.selectMask}>
            <div className={style.selectModal}>
                <div className={style.selectModalHeader}>
                    <div className={style.selectModalHeaderTitle}>{'Select Your \nCountry/Region'}</div>
                    <div className={style.selectModalHeaderClose} onClick={() => { toogleModal(false) }}><CloseIcon /></div>
                </div>
                <div className={style.selectModalMain}>
                    <div className={style.selectModalMainContent}>
                        {Object.keys(langData).map((item, index) => (<div className={style.selectModalMainContentItem} key={item}>
                            <div className={style.selectModalMainContentItemTitle}>{item}</div>
                            <div className={style.selectModalMainContentItemList}>
                                {langData[item].map((ite, idx) => (<div className={style.selectModalMainContentItemListItem} key={idx} onClick={() => { changeLang(ite.region, ite.lang) }}>
                                    <span className={style.selectModalMainContentItemListItemRegion}>{ite.regionText}</span>
                                    <span className={style.selectModalMainContentItemListItemLang}>{ite.langText}</span>
                                </div>))}
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={classNames(style.langBox,ifMenu&&style.langBoxMenu)} >
            <div className={style.langBoxContent} onClick={() => {addSensor(),  toogleModal(true),
            useGTM('navigation_click','Global/English','','footer navigation')
            }}>
      
                <FooterEarth />
                <span>{selectedCountry?.regionText} / {selectedCountry?.langText}</span>
                
            </div>
       
            {/* <SelectModal /> */}
            {modalShow && <SelectModal />}
        </div>
    )
}

export default LangList